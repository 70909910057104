
import { getPostList } from '@/api/post';
import { Component, Vue } from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import { getBestTagList } from '@/api/postTag';
import { getAlarmCount, getAlarmList } from '@/api/pushAlarm';
import { getNewAlarmCount } from '@/api/newAlarm';
import homeFooter from '@/Layout/components/homeFooter.vue';

@Component({
  components: {
    Pagination,
    homeFooter,
  },
})
export default class extends Vue {
  mounted() {
    if (this.$route.query.searchValue) {
      this.searchValue = this.$route.query.searchValue;
      this.handleSearch();
      this.getBestTagList();
      this.getAlarmList();
      this.getNewAlarmCount();
    } else {
      this.$router.push({ name: 'Home' });
    }
  }

  private newAlarmCount = 0;

  private showPopover = false;

  private alarmCount = 0;

  private alarmList = [];

  private alarmList2: any[][] = [];

  private totalElements = 0;

  private totalPages = 0;

  private listQuery = {
    page: 0,
    size: 5,
  };

  private searchValue: any = '';

  private consultList: any = [];

  private feedList: any = [];

  private consultLoading = false;

  private feedLoading = false;

  private consultTotalElements = 0;

  private feedTotalElements = 0;

  private bestTagList = [];

  private consultQuery = {
    boardUid: 'd485f6c8-ea3b-439e-9308-80a5eaf3ffe0',
    page: 1,
    size: 5,
    searchType: 'titleOrContentOrTag',
    searchValue: '',
  }

  private feedQuery = {
    boardUid: 'bb4db5fe-8083-4d87-96f7-7c1ae66b7e76',
    page: 1,
    size: 5,
    searchType: 'titleOrContentOrTag',
    searchValue: '',
  }

  private getNewAlarmCount() {
    getNewAlarmCount().then((res) => {
      this.newAlarmCount = res.data;
    });
  }

  private getConsultList() {
    this.consultLoading = true;
    getPostList(this.consultQuery).then((res) => {
      console.log(res);
      this.consultList = res.data.content;
      this.consultTotalElements = res.data.totalElements;
      this.consultLoading = false;
    });
  }

  private getFeedList() {
    this.feedLoading = true;
    getPostList(this.feedQuery).then((res) => {
      console.log(res);
      this.feedList = res.data.content;
      this.feedTotalElements = res.data.totalElements;
      this.feedLoading = false;
    });
  }

  private handleSearch() {
    if (this.searchValue) {
      this.$router.replace({ ...this.$router, query: { searchValue: this.searchValue } });
      this.consultQuery.page = 1;
      this.consultQuery.searchValue = this.searchValue;
      this.getConsultList();
      this.feedQuery.page = 1;
      this.feedQuery.searchValue = this.searchValue;
      this.getFeedList();
    } else {
      this.$message.warning('검색어를 입력해주세요.');
    }
  }

  private handleClickConsult() {
    this.$router.push({ name: 'BoardIndex', params: { boardUid: 'd485f6c8-ea3b-439e-9308-80a5eaf3ffe0' } });
  }

  private handleClickFeed() {
    this.$router.push({ name: 'BoardIndex', params: { boardUid: 'bb4db5fe-8083-4d87-96f7-7c1ae66b7e76' } });
  }

  private getBestTagList() {
    getBestTagList().then((res) => {
      this.bestTagList = res.data;
    });
  }

  private getAlarmCount() {
    getAlarmCount().then((res) => {
      this.alarmCount = res.data;
    });
  }

  private async getAlarmList() {
    await getAlarmList(this.listQuery).then((res) => {
      this.alarmList = res.data.content;
      this.totalElements = res.data.totalElements;
      this.totalPages = res.data.totalPages;
    });
    const itemMap: Map<string, any[]> = new Map();
    this.alarmList.forEach((item: any) => {
      const date: string = item.createDate.substring(0, 10); // 날짜 부분만 추출
      if (!itemMap.has(date)) {
        itemMap.set(date, []);
      }
      const itemList: any[] | undefined = itemMap.get(date);
      if (itemList) {
        itemList.push(item);
      }
    });
    this.alarmList2 = Array.from(itemMap.values());
  }

  private handleChangePage(page: number) {
    this.listQuery.page = page;
    this.getAlarmList();
  }

  private handleClickBefore() {
    this.$router.push({ name: 'Home' });
  }
}
